import React, { Component } from "react";
import { Link } from "react-router-dom";

class HeaderMenu extends Component {
  render() {
    return (
      <>
        <ul className="nav navbar-nav">
          <li className="active">
            <Link to={"/"}>
              Home
              {/* <span className="ti-home"></span> */}
            </Link>
          </li>
          <li>
            <Link to={"./about-1"}>About Us </Link>
          </li>
          <li>
            <Link to={"./team"} className="dez-page">
              Our Team
            </Link>
          </li>
          <li>
                <Link to={"./expertise"} className="dez-page">
                  Expertise
                </Link>
          </li>
          <li>
                <Link to={"./contact"} className="dez-page">
                  Contact Us
                </Link>
          </li>
          <li>
                <Link to={"./service"} className="dez-page">
                  Services
                </Link>
              </li>
          <li>
            <Link to={"./blog-standard"}>Blog </Link>
          </li>
          <li>
            <Link to={"./portfolio-grid-2"}>Our Portfolio </Link>
          </li>
        </ul>
      </>
    );
  }
}
export default HeaderMenu;
